import React,{useState, useEffect} from 'react'
import { Link} from "gatsby"
import MainView from "../../components/MainView/MainView"
//import MainViewMobile from "../components/MainView/MainView-mobile"

import Layout from "../../components/layout"
import { useMediaQuery } from 'react-responsive';
import useWindowSize from '../../hooks/useGatsbyWindowSize'



const PageDecorativeArt = (props) => {

  const [slideWidthInPixels, setSlideWidthInPixels] = useState(0);
  const [slideHeightInPixels, setSlideHeightInPixels] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const mobile = useMediaQuery({ query: `(orientation: portrait)` });
  const {width,height} = useWindowSize();

  const imageWidth = 1500;
  const imageHeight = 1680;
  const aspectRatio = imageHeight / imageWidth; //1.12


  useEffect(() => {
    console.log(props);


  },[]);


useEffect(() => {
  console.log(props);

  setIsLoaded(true);
},[mobile]);



if( mobile && isLoaded ){
return (

  <Layout mobile={mobile}>
    {/*<MainView items={1} mobile={true} width={width} height={height} location={props.location} />*/}
    <MainView slideWidth={100} slideHeight={100*aspectRatio} dataSet={2} classList={'full'}prodPerView={'auto'} perView={2} mobile={true} width={width} bg={'light'} curveTop={0} curveHeight={60} height={height} location={props.location}  />

  </Layout>

);
}
if( !mobile && isLoaded){
  return (
    <Layout mobile={mobile}>
      <MainView slideWidth={35} slideHeight={70} dataSet={2} classList={'half'} prodPerView={2} perView={3} mobile={false} width={width} bg={'light'} curveTop={-200} curveHeight={60} height={height} location={props.location}  />


      <div className="bottomHealine">
      <h3 className="fDisplay cursive">Made In<br/>Los Ángeles</h3>
      </div>
      <div className="bottomHealineRight">
      <h3 className="fDisplay cursive">Frank Ariza</h3>
      </div>

    </Layout>

  );
}
}


export default PageDecorativeArt
